import Head from 'next/head';
import { useContext, useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import RestaurantContext from '../Context/RestaurantContext';
import styled from 'styled-components';
import { useRouter } from 'next/router';

const Wrapper = styled.div`
  text-align: center;

  display: flex;
  flex-flow: column;
  align-items: center;

  & > h1 {
    margin-bottom: 15px;

    font-size: 27px;
    letter-spacing: 1.18px;
    font-weight: 900;
    @media (min-width: 768px) {
      font-size: 31px;
      letter-spacing: 1.38px;
    }
  }

  & > p {
    margin-bottom: 0;
    font-size: 15px;
    letter-spacing: 0.66px;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }

  & > span {
    font-size: 15px;
    letter-spacing: 0.66px;
    font-weight: 300;
    @media (min-width: 768px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }

  dotlottie-player {
    height: 320px;
    margin-top: -50px;
    margin-bottom: -40px;

    @media (min-width: 768px) {
      height: 420px;
      margin-left: -50px;
      margin-top: -50px;
      margin-bottom: -40px;
    }
  }

  & > .button-back {
    margin-top: 15px;
    width: 100%;
    @media (min-width: 768px) {
      min-width: 300px;
      width: auto;
    }
  }
`;

export default function Custom404() {
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const router = useRouter();

  useEffect(() => {
    import('@dotlottie/player-component');
  }, []);

  const title = `${storeProps.restaurantName} - 404 - Page not found`;
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <Container>
        <Wrapper>
          <dotlottie-player autoplay loop mode="client" src="/LottieFiles/404.lottie" />

          <h1>404</h1>

          <p>Seite konnte leider nicht gefunden werden.</p>
          <span>
            Falls du der Meinung bist, da stimmt was nicht, freuen wir uns von dir zu hören!
          </span>

          <Button
            variant="primary"
            size="lg"
            type="submit"
            className="button-back"
            onClick={() => router.push('/')}
          >
            Zur Startseite
          </Button>
        </Wrapper>
      </Container>
    </>
  );
}
